<template>
  <div deposit-limit-re-check>
    <p class="title">
      <template v-for="obj in subTitleObjs">
        <template v-if="obj.key.toLowerCase() === '#text'">
          <label v-html="obj.label" />
        </template>
        <template v-if="obj.key.toLowerCase() === 'a'">
          <a href="#" @click="openWeb(subTitleLink)">{{ obj.label }}</a>
        </template>
      </template>
    </p>
    <div class="description">
      <div>
        <span v-html="$t('_.description.guide2')" />
      </div>
      <div>
        <span v-html="$t('_.description.guide3', { amount: `${currencySymbol}${currentMonthlyLimit}`, month: $t('month').toLowerCase()})" />
      </div>
      <div>
        <span v-html="$t('_.description.guide4')" />
        <span>
          <template v-for="obj in loketKansspelObjs">
            <template v-if="obj.key.toLowerCase() === '#text'">
              {{ obj.label }}
            </template>
            <template v-if="obj.key.toLowerCase() === 'a'">
              <a href="#" @click="openWeb(loketKansspelLink)">{{ obj.label }}</a>
            </template>
          </template>
        </span>
      </div>
      <div>
        <span>
          <template v-for="obj in cruksObjs">
            <template v-if="obj.key.toLowerCase() === '#text'">
              {{ obj.label }}
            </template>
            <template v-if="obj.key.toLowerCase() === 'a'">
              <a href="#" @click="openWeb(cruksLink)">{{ obj.label }}</a>
            </template>
          </template>
        </span>
      </div>
    </div>
    <div class="footer">
      <p v-html="$t('_.button.guide')" />
      <GpButton type="submit-green" @click="setDepositLimit" :disabled="isDisable">{{ buttonText }}</GpButton>
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { openWeb } from '@/utils/clientUtil';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import { routeNames } from '@/constants/model/responsible-gaming';

export default {
  name: 'RgDepositLimitReCheck',
  lexicon: 'responsibleGaming.webviewModal',
  components: { GpButton },
  data() {
    return {
      subTitleLink: 'https://zoek.officielebekendmakingen.nl/stcrt-2024-19648.html',
      loketKansspelLink: 'https://www.loketkansspel.nl/index-en.html',
      cruksLink: 'https://cruksregister.nl',
      subTitleObjs: [],
      loketKansspelObjs: [],
      cruksObjs: [],
      currentMonthlyLimit: 0,
      count: 30,
    };
  },
  computed: {
    site: state('env', 'site'),
    userInfo: state('user', 'userInfo'),
    currencySymbol: state('user', 'currencySymbol'),
    isDisable() {
      return !!this.count;
    },
    buttonText() {
      return this.isDisable ? this.$t('_.button.disableButton', { second: this.count }) : this.$t('_.button.setDeposit');
    }
  },
  methods: {
    async openWeb(url) {
      await openWeb(this, url);
    },
    async setDepositLimit() {
      if (this.isDisable) return;

      try {
        const r = await this.$services.account.setRResponsibleGamingReCheckTarget();
        if (!r.error) {
          this.$store.commit('user/setReCheckTarget', true);
          await this.replaceRouteName(routeNames.RgMyDepositLimit);
        } else await this.replaceRouteName('AuthError');
      } catch (e) {
        await this.replaceRouteName('AuthError');
      }
    },
    convertLabelObject(label) {
      if (!label) return;

      const arrLabelObj = [];
      const parser = new DOMParser();
      const nodeList = parser.parseFromString(label, "text/html")?.body?.childNodes;

      nodeList.forEach(node => {
        const { nodeName, nodeValue, innerHTML } = node;
        arrLabelObj.push({key: nodeName, label: innerHTML || nodeValue});
      });

      return arrLabelObj;
    },
    async initialize() {
      this.subTitleObjs = this.convertLabelObject(this.$t('_.description.guide1'));
      this.loketKansspelObjs = this.convertLabelObject(this.$t('_.description.guide5'));
      this.cruksObjs = this.convertLabelObject(this.$t('_.description.guide6'));

      const r = await this.$services.account.getDepositLimit();
      if (!r.error) this.currentMonthlyLimit = r.CurrentMonthlyDepositLimit;
      let intervalId = setInterval(() => {
        if (!this.count) {
          clearInterval(intervalId);
          intervalId = null;
        } else {
          this.count--;
        }
      }, 1000);
    },
  },
  async mounted() {
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[deposit-limit-re-check] { .ph(20); .bgc(#F7F7F8);
  .title { .m(auto); .tc(); .mt(60); .mb(24); .max-w(460); white-space: normal;
    label { .inline(); .fs(16, 22, 0.48); .c(#000); }
    a { .inline(); .fs(16, 22, 0.48); .c(#1570ef); }
  }
  .description { .fs(14, 20, 0.42); .c(#737373);
    div {
      span { .block(); }
      a { .fs(14, 20, 0.42); .c(#1570ef); }
    }
    div + div { .mt(20); }
  }
  .footer { .tc(); .mt(90); .fs(14, 20, 0.42); .c(#737373);
    [color-button] { .mt(16); .max-w(460); .h(48); .fs(16); }
  }
}
</style>
